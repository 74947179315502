/*
 * Module two-column-images (two-column-images)
 * Added by karolinanorstrom, 2024-02-15T11:43:35.845Z
 *
 * This file is used on both desktop and mobile.
 */
section.two-column-images {
  margin-left: 96px;
  margin-right: 96px;
}
section.two-column-images .image-container {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
section.two-column-images .image-container .image-container-inner {
  display: block;
  width: calc(50% - 16px);
  margin-bottom: 20px;
  height: auto;
  align-self: flex-start;
}