/*
 * Module text-area-full-width (text-area-full-width)
 * Added by karolinanorstrom, 2024-03-04T14:41:27.766Z
 *
 * This file is used on both desktop and mobile.
 */
section.text-area-full-width p {
  font-family: var(--p2-font);
  font-size: 1.125rem;
}
section.text-area-full-width .area-container {
  margin-left: 120px;
  margin-right: 120px;
}