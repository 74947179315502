/*
 * Module text-section (text-section)
 * Added by karolinanorstrom, 2024-02-01T14:00:39.551Z
 *
 * This file is used on both desktop and mobile.
 */
section.text-section {
  width: 100%;
  max-width: 1440px;
  padding: 0 40px;
  margin: 20px auto 40px;
}
section.text-section .text-container {
  font-family: var(--p1-font);
  font-size: var(--p2-size);
  width: 100%;
  max-width: 680px;
  margin: 0;
}
section.text-section .text-container.text-container-center {
  max-width: 680px;
  margin: 0 auto;
}
section.text-section .text-container h1,
section.text-section .text-container h2,
section.text-section .text-container h3,
section.text-section .text-container h4,
section.text-section .text-container h5,
section.text-section .text-container h6 {
  font-size: var(--h2-size);
  font-family: var(--h2-font);
}