/*
 * Module Image (image)
 * Added by henrik, 2024-02-02T10:35:58.340Z
 *
 * This file is used on both desktop and mobile.
 */
section.image img {
  width: 100%;
}
section.image div {
  width: 100%;
  aspect-ratio: 16/9;
}