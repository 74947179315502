/*
 * Module three-column-with-text-and-associated-image (three-column-with-text-and-associated-image)
 * Added by karolinanorstrom, 2024-02-08T14:24:36.808Z
 *
 * This file is used on both desktop and mobile.
 */
section.three-column-with-text-and-associated-image {
  display: block !important;
  width: auto;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
section.three-column-with-text-and-associated-image .heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 90px;
}
section.three-column-with-text-and-associated-image .heading-container h2 {
  color: var(--h2-color);
  font-size: var(--h2-size);
  font-family: var(--h2-font);
  margin-bottom: 30px;
}
section.three-column-with-text-and-associated-image .heading-container p {
  color: var(--p1-color);
  font-size: var(--p1-size);
  font-family: var(--p1-font);
  width: 55%;
}
section.three-column-with-text-and-associated-image .image-container {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-top: 70px;
}
section.three-column-with-text-and-associated-image .image-container .group-container {
  width: calc(33.33% - 20px);
  display: flex;
  flex-direction: column;
}
section.three-column-with-text-and-associated-image .image-container h3 {
  color: var(--h3-color);
  font-size: var(--h3-size);
  font-family: var(--h3-font);
  margin-top: 20px;
  margin-bottom: 15px;
}
section.three-column-with-text-and-associated-image .image-container p {
  color: var(--p2-color);
  font-size: var(--p2-size);
  font-family: var(--p2-font);
}
section.three-column-with-text-and-associated-image .image-container a {
  color: var(--p2-color);
  font-size: var(--p2-size);
  font-family: var(--p2-font);
  font-weight: 600;
}
section.three-column-with-text-and-associated-image .spacer {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1200px) {
  section.three-column-with-text-and-associated-image .image-container h3 {
    font-size: 1.25rem;
  }
}