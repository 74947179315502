/*
 * Module Columns (columns)
 * Added by henrik, 2024-02-02T10:47:39.721Z
 *
 * This file is used on both desktop and mobile.
 */
section.columns margins {
  display: flex !important;
  flex-shrink: 1;
  gap: var(--section-internal-gap);
}
section.columns margins a {
  display: block;
  flex: 1;
}
section.columns margins a column {
  display: block;
  overflow: hidden;
}
section.columns margins column {
  display: block;
  overflow: hidden;
  flex: 1;
}
section.columns .image {
  width: 100%;
  height: initial !important;
  aspect-ratio: 4/3;
}