/*
 * Module Hero (hero)
 * Added by karolinanorstrom, 2024-02-05T11:18:02.142Z
 *
 * This file is used on both desktop and mobile.
 */
section.hero .content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
}
section.hero .hero-container {
  height: 676px;
  background-size: cover;
  background-position: center;
}
section.hero .hero-container .hero-container-mobile {
  display: none;
}
section.hero .hero-container h1 {
  color: var(--h1-color);
  padding-top: 10%;
  font-size: var(--h1-size);
  font-family: var(--h1-font);
  padding-bottom: 25px;
  line-height: 110%;
  letter-spacing: -1px;
}
section.hero .hero-container .hero-text-container {
  margin-left: 7%;
  margin-right: 7%;
}
section.hero .hero-container .hero-text-container .hero-body-text {
  font-family: var(--p1-font);
  font-size: var(--p1-size);
  color: var(--h1-color);
  line-height: 140%;
  font-weight: 700;
}
section.hero .hero-container .services-container-inner {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: 7%;
}
section.hero .hero-container .services-container-inner p {
  font-size: var(--p2-size);
  font-weight: 500;
  height: 36px;
  display: flex;
  align-items: center;
  padding-right: 14px;
  padding-left: 14px;
  background-color: #00ffd1;
  font-family: var(--p1-font);
  margin: 0px;
}
section.hero .hero-container .hero-left {
  width: 55%;
}
section.hero .hero-container .hero-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
section.hero .hero-container .hero-right .reference-year {
  color: var(--h1-color);
  font-size: 5.125rem;
  font-family: var(--h1-font);
  width: 40%;
  max-width: 250px;
  letter-spacing: -1px;
  line-height: 80%;
}
section.hero .hero-container .inner-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  padding-bottom: 55px;
}
section.hero .hero-container .banner-roll {
  width: 100%;
  position: relative;
  animation-name: banner;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
section.hero .hero-container .banner-roll.desktop {
  height: 50px;
  margin-bottom: 55px;
}
section.hero .hero-container .banner-roll.mobile {
  height: 35px;
  margin-bottom: 16px;
}
@keyframes banner {
  0% {
    left: 0%;
    top: 0px;
  }
  100% {
    left: -2065px;
    top: 0px;
  }
}
section.hero .hero-container-full-height {
  height: 100vh;
  background-size: cover;
}
@media (min-width: 800px) and (max-width: 1005px) {
  section.hero .hero-left {
    width: 100% !important;
  }
  section.hero .reference-year {
    width: 100% !important;
  }
}