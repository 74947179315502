/*
 * Module image-full-width-block (image-full-width-block)
 * Added by karolinanorstrom, 2024-02-13T14:46:33.392Z
 *
 * This file is used on both desktop and mobile.
 */
section.image-full-width-block {
  margin-left: 96px;
  margin-right: 96px;
}
section.image-full-width-block .image-container {
  width: 100%;
  height: auto;
}