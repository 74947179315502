/*
 * Module three-column-two-text-items-and-img (three-column-two-text-items-and-img)
 * Added by karolinanorstrom, 2024-02-21T11:22:53.237Z
 *
 * This file is used on both desktop and mobile.
 */
section.three-column-two-text-items-and-img {
  display: flex !important;
  justify-content: space-between;
  margin-left: 96px;
  margin-right: 96px;
  gap: 30px;
}
section.three-column-two-text-items-and-img .image-container {
  display: flex;
  gap: 30px;
  width: 33%;
}
section.three-column-two-text-items-and-img .image-container .image-container-inner {
  height: auto;
  width: 100%;
}
section.three-column-two-text-items-and-img .heading-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
section.three-column-two-text-items-and-img .heading-container p {
  color: var(--p2-color);
  font-size: var(--p2-size);
  font-family: var(--p2-font);
}
section.three-column-two-text-items-and-img .heading-container h3 {
  font-size: var(--h3-size);
  font-family: var(--h3-font);
}
section.three-column-two-text-items-and-img .heading-container .background-text {
  color: #00FFD1;
  font-size: 4rem;
  font-family: --h1-font;
  line-height: 110%;
  letter-spacing: -1px;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: -1;
  opacity: 30%;
}
@media (min-width: 800px) and (max-width: 1005px) {
  section.three-column-two-text-items-and-img {
    display: block !important;
  }
  section.three-column-two-text-items-and-img .heading-container {
    width: 100%;
    margin-bottom: 24px;
  }
  section.three-column-two-text-items-and-img .image-container {
    width: 100%;
    display: block;
  }
  section.three-column-two-text-items-and-img .image-container .image-container-inner {
    width: 100%;
  }
}