/*
 * Module text-area (text-area)
 * Added by karolinanorstrom, 2024-02-20T09:46:54.442Z
 *
 * This file is used on both desktop and mobile.
 */
section.text-area {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
section.text-area p {
  width: 670px;
  color: var(--p2-color);
  font-size: var(--p2-size);
  font-family: var(--p2-font);
}
section.text-area a {
  color: black;
}