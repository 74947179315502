/*
 * Module Heading (heading)
 * Added by karolinanorstrom, 2024-02-05T08:58:42.401Z
 *
 * This file is used on both desktop and mobile.
 */
section.heading .heading-container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
section.heading .heading-container h2 {
  color: var(--h2-color);
  font-size: var(--h2-size);
  font-family: var(--h2-font);
}
section.heading .heading-container p {
  color: var(--h2-color);
  font-size: var(--p1-size);
  font-family: var(--p1-font);
}
section.heading .heading-container .spacer {
  width: 124px;
  height: 14px;
  background-color: #00FFD1;
  margin: auto;
  margin-top: 26px;
  margin-bottom: 45px;
}