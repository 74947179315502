/*
 * Module suppliers-section (suppliers-section)
 * Added by viktorandersson, 2024-02-22T12:53:43.154Z
 *
 * This file is used on both desktop and mobile.
 */
section.suppliers-section {
  width: 100%;
  max-width: 1440px;
  padding: 0 40px;
  margin: 20px auto 70px !important;
}
section.suppliers-section .suppliers-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 30px;
}
section.suppliers-section .suppliers-container a {
  display: block;
  max-width: calc(25% - 22.5px);
}
section.suppliers-section .suppliers-container a img {
  width: 100%;
  display: block;
}