/*
 * Module three-column-text-and-two-images (three-column-text-and-two-images)
 * Added by karolinanorstrom, 2024-02-13T13:22:02.916Z
 *
 * This file is used on both desktop and mobile.
 */
section.three-column-text-and-two-images {
  display: flex !important;
  justify-content: center;
  margin-left: 96px;
  margin-right: 96px;
}
section.three-column-text-and-two-images .content-container {
  display: flex;
  width: 100%;
}
section.three-column-text-and-two-images .image-container {
  display: flex;
  gap: 30px;
  width: 66%;
}
section.three-column-text-and-two-images .image-container .image-container-inner {
  height: auto;
  width: calc(50% - 15px);
  align-self: flex-start;
}
section.three-column-text-and-two-images .heading-container {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-right: 30px;
}
section.three-column-text-and-two-images .heading-container p {
  color: var(--p2-color);
  font-size: var(--p2-size);
  font-family: var(--p2-font);
}
section.three-column-text-and-two-images .heading-container h3 {
  font-size: var(--h3-size);
  font-family: var(--h3-font);
  margin-bottom: 18px;
}
section.three-column-text-and-two-images .heading-container .background-text {
  color: #00FFD1;
  font-size: 4rem;
  font-family: var(--h1-font);
  line-height: 110%;
  letter-spacing: -1px;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: -1;
  opacity: 30%;
}
@media (min-width: 800px) and (max-width: 1005px) {
  section.three-column-text-and-two-images {
    display: block !important;
  }
  section.three-column-text-and-two-images .content-container {
    display: block;
  }
  section.three-column-text-and-two-images .heading-container {
    width: 100%;
  }
  section.three-column-text-and-two-images .image-container {
    margin-top: 24px;
    width: 100%;
  }
}