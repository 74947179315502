/*
 * Module reference-section (reference-section)
 * Added by viktorandersson, 2024-02-19T14:36:52.843Z
 *
 * This file is used on both desktop and mobile.
 */
.reference-section {
  margin-left: 42px;
  margin-right: 42px;
}

.reference-container {
  display: flex;
  gap: 30px;
}
.reference-container.reference-container-mobile {
  display: none;
}
.reference-container .references {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.reference-container .references a {
  color: var(--h1-color);
  text-decoration: none;
  font-size: var(--h2-size);
  font-family: var(--h1-font);
  position: relative;
}
.reference-container .references a h3 {
  font-size: 2rem;
  position: absolute;
  bottom: 8px;
  left: 16px;
}
.reference-container .references .reference-grid-item {
  width: 100%;
  background-size: cover;
  background-position: center;
  font-family: var(--h1-font);
  display: flex;
  align-items: flex-end;
}
.reference-container .references .reference-grid-item.landscape {
  height: auto;
}
.reference-container .references .reference-grid-item.portrait {
  height: auto;
}

@media only screen and (max-width: 1200px) {
  .reference-container .references .reference-grid-item h3 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1000px) {
  .reference-container.reference-container-desktop {
    display: none;
  }
  .reference-container.reference-container-mobile {
    display: flex;
  }
  .reference-container .references .reference-grid-item h3 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 600px) {
  .reference-container.reference-container-mobile {
    display: block;
  }
  .reference-container .references.references-mobile {
    margin-bottom: 30px;
  }
  .reference-container .references .reference-grid-item h3 {
    font-size: 1.5rem;
  }
}