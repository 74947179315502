/*
 * Module img-and-text-section (img-and-text-section)
 * Added by karolinanorstrom, 2024-02-12T20:07:41.243Z
 *
 * This file is used on both desktop and mobile.
 */
section.img-and-text-section {
  display: flex !important;
  justify-content: center;
  margin-left: 96px;
  margin-right: 96px;
}
section.img-and-text-section .image-container {
  width: 66%;
}
section.img-and-text-section .image-container .image-container-inner {
  height: auto;
  width: 100%;
}
section.img-and-text-section .heading-container {
  width: 33%;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
section.img-and-text-section .heading-container p {
  color: var(--p2-color);
  font-size: var(--p2-size);
  font-family: var(--p2-font);
}
section.img-and-text-section .heading-container h3 {
  font-size: var(--h3-size);
  font-family: var(--h3-font);
  margin-bottom: 18px;
}
section.img-and-text-section .heading-container .background-text {
  color: #00FFD1;
  font-size: 4rem;
  font-family: var(--h1-font);
  line-height: 110%;
  letter-spacing: -1px;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: -1;
  opacity: 30%;
}
@media (min-width: 800px) and (max-width: 1005px) {
  section.img-and-text-section {
    display: block !important;
  }
  section.img-and-text-section .image-container {
    width: 100%;
  }
  section.img-and-text-section .heading-container {
    margin-left: 0px;
    width: 100%;
    margin-top: 36px;
  }
}