/*
 * Module image-and-text-full-width-block (image-and-text-full-width-block)
 * Added by karolinanorstrom, 2024-02-14T10:47:23.148Z
 *
 * This file is used on both desktop and mobile.
 */
section.image-and-text-full-width-block {
  margin-left: 96px;
  margin-right: 96px;
}
section.image-and-text-full-width-block .image-container {
  width: 100%;
  height: auto;
}
section.image-and-text-full-width-block .text-container {
  justify-content: center;
  display: flex;
  margin-top: 24px;
}
section.image-and-text-full-width-block .text-container p {
  font-family: var(--p2-font);
  font-size: var(--p2-size);
}
@media (min-width: 800px) and (max-width: 1005px) {
  section.image-and-text-full-width-block .text-container {
    display: block;
  }
  section.image-and-text-full-width-block .text-container p {
    width: 100%;
  }
}