/*
 * Module how-does-it-work-block (how-does-it-work-block)
 * Added by karolinanorstrom, 2024-02-16T09:14:37.118Z
 *
 * This file is used on both desktop and mobile.
 */
section.how-does-it-work-block {
  margin-left: 42px;
  margin-right: 42px;
}
section.how-does-it-work-block .outer-container .vline {
  content: "";
  background-color: var(--primary-color-storey);
  position: absolute;
  width: 2px;
  height: 365px;
  left: 50%;
  display: block;
}
section.how-does-it-work-block .outer-container .vline .circle {
  width: 26px;
  height: 26px;
  background-color: var(--primary-color-storey);
  border-radius: 50%;
  position: absolute;
  left: -12px;
}
section.how-does-it-work-block .outer-container h1 {
  color: var(--h2-color);
  font-size: var(--h1-size);
  font-family: var(--h1-font);
  line-height: 110%;
  letter-spacing: -1px;
  text-align: center;
  padding-top: 90px;
}
section.how-does-it-work-block .outer-container .background-image {
  height: 2344px;
  position: relative;
  z-index: -1;
}
section.how-does-it-work-block .outer-container .spacer {
  width: 124px;
  height: 14px;
  background-color: var(--primary-color-storey);
  margin: auto;
  margin-top: 26px;
  margin-bottom: 200px;
}
section.how-does-it-work-block .outer-container .right-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
section.how-does-it-work-block .outer-container .left-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
section.how-does-it-work-block .outer-container .left-block .last-line {
  position: absolute;
  left: 50%;
}
section.how-does-it-work-block .outer-container .left-block .last-line .circle {
  background-color: var(--primary-color-storey);
  border-radius: 50%;
  height: 26px;
  left: -12px;
  top: 90px;
  position: absolute;
  width: 26px;
}
section.how-does-it-work-block .outer-container .inner-container {
  width: 365px;
  margin-left: 130px;
  margin-right: 130px;
  position: relative;
}
section.how-does-it-work-block .outer-container .inner-container .background-text {
  font-size: 8.25rem;
  color: var(--primary-color-storey);
  position: absolute;
  top: -70px;
  left: -50px;
  z-index: -1;
  font-family: var(--h2-font);
}
section.how-does-it-work-block .outer-container .inner-container h2 {
  color: var(--h2-color);
  font-size: var(--h2-size);
  font-family: var(--h2-font);
}
section.how-does-it-work-block .outer-container .inner-container p {
  color: var(--p1-color);
  font-size: var(--p1-size);
  font-family: var(--p1-font);
}

@media only screen and (max-width: 1250px) {
  section.how-does-it-work-block .outer-container .inner-container {
    margin-left: 80px;
    margin-right: 80px;
  }
}
@media only screen and (max-width: 1100px) {
  section.how-does-it-work-block .outer-container .inner-container {
    width: 275px;
    margin-left: 50px;
    margin-right: 50px;
  }
  section.how-does-it-work-block .outer-container .inner-container .background-text-first-number {
    font-size: 8.25rem;
    left: -50px;
    position: absolute;
    top: -70px;
  }
  section.how-does-it-work-block .outer-container .inner-container h2 {
    font-size: var(--h3-size);
  }
  section.how-does-it-work-block .outer-container .inner-container p {
    font-size: var(--p2-size);
  }
}
@media only screen and (max-width: 900px) {
  section.how-does-it-work-block .outer-container .inner-container {
    width: 215px;
  }
}