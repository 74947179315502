/*
 * Module button (button)
 * Added by karolinanorstrom, 2024-03-05T09:13:22.167Z
 *
 * This file is used on both desktop and mobile.
 */
section.button {
  display: flex !important;
  justify-content: center;
}
section.button .button-link-container {
  background-color: var(--primary-color-storey);
  width: 169px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.button .button-link-container a {
  text-decoration: none;
  color: var(--h2-color);
  font-family: var(--h2-font);
  font-size: var(--button-size-text);
}