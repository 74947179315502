/*
 * Module reference-logotype-section (reference-logotype-section)
 * Added by karolinanorstrom, 2024-04-16T13:09:32.790Z
 *
 * This file is used on both desktop and mobile.
 */
section.reference-logotype-section {
  width: 100%;
  max-width: 1440px;
  padding: 0 40px;
  margin: 20px auto 70px !important;
}
section.reference-logotype-section .heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
section.reference-logotype-section .heading-container h2 {
  color: var(--h2-color);
  font-family: var(--h2-font);
  font-size: var(--h2-size);
}
section.reference-logotype-section .reference-logo-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  width: 100%;
  max-width: 1060px;
  margin: 0 auto;
}
section.reference-logotype-section .reference-logo-container a {
  display: flex;
  max-width: calc(16% - 22.5px);
  justify-content: center;
}
section.reference-logotype-section .reference-logo-container a img {
  width: auto;
  max-width: 70%;
  filter: grayscale(1);
  vertical-align: middle;
}