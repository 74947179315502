/*
 * Module two-column-text-and-image-right (two-column-text-and-image-right)
 * Added by karolinanorstrom, 2024-02-13T12:48:50.625Z
 *
 * This file is used on both desktop and mobile.
 */
section.two-column-text-and-image-right {
  display: flex !important;
  margin-left: 128px;
  margin-right: 128px;
  justify-content: center;
  align-items: center;
  height: auto;
}
section.two-column-text-and-image-right .heading-container {
  width: 38%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 30px;
}
section.two-column-text-and-image-right .heading-container h2 {
  color: var(--h2-color);
  font-size: var(--h2-size);
  font-family: var(--h2-font);
  margin-bottom: 30px;
}
section.two-column-text-and-image-right .heading-container p {
  color: var(--p1-color);
  font-size: var(--p1-size);
  font-family: var(--p1-font);
  margin-right: 30px;
}
section.two-column-text-and-image-right .image-container {
  width: 66%;
}
section.two-column-text-and-image-right .image-container .image-container-inner {
  height: auto;
  width: 100%;
}
section.two-column-text-and-image-right .link-container {
  display: flex;
  justify-content: center;
}
section.two-column-text-and-image-right .link-container .border {
  padding-top: 30px;
  border-bottom: 3px solid var(--primary-color-storey);
}
section.two-column-text-and-image-right .link-container a {
  font-size: var(--h3-size);
  font-family: var(--h3-font);
  color: var(--h2-color);
  text-decoration: none;
}
@media (min-width: 800px) and (max-width: 1005px) {
  section.two-column-text-and-image-right {
    display: block !important;
    text-align: center;
  }
  section.two-column-text-and-image-right .heading-container {
    width: 100%;
    display: block;
    padding-bottom: 24px;
  }
  section.two-column-text-and-image-right .heading-container h2 {
    color: var(--mobile-h1-color);
    font-size: var(--mobile-h1-size);
  }
  section.two-column-text-and-image-right .image-container {
    width: 100%;
  }
  section.two-column-text-and-image-right .image-container .image-container-inner {
    height: auto;
    width: calc(100% - 15px);
  }
}