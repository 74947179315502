/*
 * Module ongoing-project (ongoing-project)
 * Added by karolinanorstrom, 2024-02-06T13:24:10.801Z
 *
 * This file is used on both desktop and mobile.
 */
section.ongoing-project .ongoing-project-container {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin-left: 42px;
  margin-right: 42px;
}
section.ongoing-project .ongoing-project-container .image-container {
  display: flex;
  flex: 1;
  gap: 30px;
  height: auto;
  overflow: hidden;
  position: relative;
  align-items: center;
}
section.ongoing-project .ongoing-project-container .image-container .first-image-container {
  flex: 1;
  overflow: hidden;
  width: 100%;
}
section.ongoing-project .ongoing-project-container .image-container .first-image-container .image-container-inner-first {
  height: auto;
  width: 100%;
}
section.ongoing-project .ongoing-project-container .image-container .second-image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
section.ongoing-project .ongoing-project-container .image-container .second-image-container .image-container-inner {
  height: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
section.ongoing-project .ongoing-project-container .image-container .second-image-container img {
  width: 100%;
}
section.ongoing-project .ongoing-project-container .text-container {
  width: 50%;
  padding-left: 30px;
}
section.ongoing-project .ongoing-project-container .text-container h2 {
  color: var(--h2-color);
  font-size: var(--h2-size);
  font-family: var(--h2-font);
  margin-bottom: 30px;
}
section.ongoing-project .ongoing-project-container .text-container p {
  color: var(--h2-color);
  font-size: var(--p1-size);
  font-family: var(--p1-font);
}
section.ongoing-project .ongoing-project-container .text-container .services-container {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: left;
}
section.ongoing-project .ongoing-project-container .text-container .services-container p {
  font-size: 17px;
  font-weight: 500;
  height: 36px;
  display: flex;
  align-items: center;
  padding-right: 14px;
  padding-left: 14px;
  background-color: #00ffd1;
}
section.ongoing-project .ongoing-project-container .link-container {
  display: flex;
  justify-content: center;
}
section.ongoing-project .ongoing-project-container .link-container .border {
  padding-top: 30px;
  border-bottom: 5px solid var(--primary-color-storey);
}
section.ongoing-project .ongoing-project-container .link-container a {
  font-size: var(--h3-size);
  font-family: var(--h3-font);
  color: var(--h2-color);
  text-decoration: none;
}
@media (min-width: 800px) and (max-width: 1005px) {
  section.ongoing-project .ongoing-project-container {
    display: block;
    height: 100%;
  }
  section.ongoing-project .ongoing-project-container .image-container {
    display: flex;
    height: auto;
  }
  section.ongoing-project .ongoing-project-container .image-container .first-image-container {
    width: 100%;
  }
  section.ongoing-project .ongoing-project-container .image-container .second-image-container {
    width: 100%;
  }
  section.ongoing-project .ongoing-project-container .text-container {
    width: 100%;
    padding: 0px;
  }
  section.ongoing-project .ongoing-project-container .text-container h2 {
    color: var(--mobile-h1-color);
    font-size: var(--mobile-h1-size);
    margin-top: 16px;
  }
  section.ongoing-project .ongoing-project-container .text-container h2 .services-container {
    font-size: 14px;
  }
}